import { Pipe, PipeTransform } from '@angular/core';

import { File as FileModel } from '@alan-apps/data-access';

/**
 * default: result[1],
        mq: result[2],
        hq: result[3],
        sd: result[4],
        maxresde
 */
@Pipe({
  name: 'img',
  standalone: true,
})
export class ImgPipe implements PipeTransform {
  // constructor(
  //   // @Attribute('img-size') private imgSize: string,
  //   private elm: ElementRef) {AfterViewInit
  //   console.log(this.elm.nativeElement);

  // }

  // ngAfterViewInit(): void {
  //   console.log((<HTMLElement>this.elm.nativeElement).attributes.getNamedItem('img-size'));
  // }

  transform(file: FileModel, type = 'default'): any {
    try {
      // TODO: make that alway to be maxresde
      return file.path['maxresde'] || file.path[type] || file.path['default'];
      // if (environment.production) {
      // }

      // return file.path['default'];
    } catch (error) {
      return '';
    }
  }
}
